<template>
  <div>
    <template v-if="profiles.length == 0">
      <div class="py-12 flex items-center justify-center">
        <loading-icon class="h-6 w-6" />
      </div>
    </template>

    <section v-for="profileName in profiles" :key="`${profileName}-profile-form`">
      <transition
        appear
        enter-active-class="transition ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class=""
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        mode="out-in"
      >
        <section v-if="profileName == activeForm" :key="profileName" class="mt-12">
          <component :is="`${toStartCase(profileName)}Form`" :key="`${profileName}-form`" />
        </section>
      </transition>
    </section>
  </div>
</template>

<script>
  import { startCase } from 'lodash'
  import GuestForm from '@/components/register/GuestForm'
  import ShowForm from '@/components/register/ShowForm'
  export default {
    name: 'ProfileSetup',

    components: {
      ShowForm,
      GuestForm,
    },

    data() {
      return {
        loading: false,
        activeForm: null,
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      profiles() {
        if (! this.profileType) {
          return []
        }

        return this.profileType != 'both' ? [this.profileType] : ['guest', 'show']
      },

      profileType() {
        return this.$store.getters['auth/profileType']
      },

      currentStep() {
        return this.$store.getters['register/currentStep']
      }
    },

    watch: {
      // profiles(newProfiles) {
      //   this.activeForm = newProfiles[0]
      // },

      currentStep(step) {
        if (step == 4) {
          this.activeForm = 'guest'
        } else if( step == 5) {
          this.activeForm = 'show'
        }

        window.scrollTo({top: 0, behavior: 'smooth'})
      }
    },

    methods: {
      toStartCase(string) {
        return startCase(string)
      },
    },

    mounted() {
      window.Event.$on('skipForm', index => {
        if (typeof this.profiles[index + 1] != 'undefined') {
          this.activeForm = this.profiles[index + 1]
          return
        }

        this.$router.push({name: 'register-guest-social-links'})
      })

      window.Event.$on('profileFormSubmitted', form => {
        let index = this.profiles.indexOf(form)

        if (index != -1 && typeof this.profiles[index+1] != 'undefined') {
          this.activeForm = this.profiles[index + 1]
          return
        }

        this.$router.push({name: 'register-guest-social-links'})
      })

      this.activeForm = this.profiles[0] || null

      this.$store.commit('register/setCurrentStep', this.activeForm == 'show' ? 5 : 4)

      if (! this.profileType) {
        this.$router.push({name: 'register-profile-type'})
      }
    },

    destroyed() {
      window.Event.$off('profileFormSubmitted')
      window.Event.$off('skipForm')
    }
  }
</script>
