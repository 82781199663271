<template>
  <div class="flex relative rounded-full border border-guestio-gray-300">
    <multiselect
      class="new-multiselect"
      id="ajax"
      v-model="selected"
      :options="options"
      :show-labels="false"
      placeholder="Your podcast name"
      track-by="name"
      label="name"
      :allow-empty="false"
      :max-height="searchable ? 300 : 0"
      :loading="loading"
      :internal-search="false"
      :searchable="searchable"
      :options-limit="50"
      :limit="10"
      @input="setCurrentPodcast"
      @search-change="search"
    >
      <span slot="caret"></span>

      <template slot="option" slot-scope="props">
        <img
          class="option__image"
          :src="props.option.img"
          :alt="props.option.title"
        />
        <div class="option__desc">
          <span class="option__title">{{ props.option.title }}</span>
          <span class="option__small">{{ props.option.desc }}</span>
        </div>
      </template>

      <template slot="singleLabel" slot-scope="{ option }">
        <p v-if="option.desc" class="truncate" :class="{ 'mr-32': withButton }">
          <strong>{{ option.title }}</strong> {{ option.desc }}
        </p>
        <p v-else class="truncate" :class="{ 'mr-32': withButton }">
          {{ option.title }}
        </p>
      </template>

      <template slot="noOptions">
        <p>Enter 3 or more characters</p>
      </template>
    </multiselect>
    <slot name="button" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from "axios";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";


export default {
  name: "RephonicSearch",
  components: { Multiselect },
  props: {
    deleted: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },

  data() {
    return {
      options: [],
      selected: null,
      withButton: true,
      loading: false,
      rephonicUrl: "https://api.rephonic.com/api",
      config: {
        headers: { "X-Rephonic-Api-Key": process.env.VUE_APP_REPHONIC_API_KEY }
      },
    };
  },

  watch: {
    selected(selected) {
      this.$emit('selected', selected);
    },

    deleted(deleted) {
      if (deleted) {
        this.selected = null;
      }
    }
  },

  computed: {
    ...mapState({
      show: state => state.shows.show
    }),

    corsProxyUrl() {
      return process.env.VUE_APP_CORS_PROXY_URL
    },
  },

  mounted() {
    const stats = this.show.rephonic_stats;
    if (stats) {
      this.selected = {
        title: stats.name,
        desc: `${stats.num_episodes} Episodes - ${stats.publisher_name}`
      };
    }
  },

  methods: {
    rephonicSearch: debounce(async function(query) {
      try {
        this.$emit('loading', true);
        this.loading = true;
        this.config.params = {
          mode: "titles",
          query: query
        };
        const url = `${this.corsProxyUrl}${this.rephonicUrl}`
        const { data } = await axios.get(
          `${url}/search/podcasts/`,
          this.config
        );
        this.options = data.podcasts.map(result => {
          return {
            img: result.artwork_url,
            title: result.name,
            desc: `${result.num_episodes} Episodes - ${result.publisher.name}`,
            rephonicId: result.id
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.$emit('loading', false);
      }
    }, 750),

    async search(query) {
      if (query && query.length > 2) {
        await this.rephonicSearch(query);
      } else {
        this.options = [];
      }
    },

    async setCurrentPodcast(selected) {
      this.$emit('selected', selected);
    },
  },
};
</script>

<style>
.new-multiselect {
  @apply rounded-full !important;
}

.new-multiselect .multiselect__placeholder {
  @apply text-grayDark mb-0 !important;
}

.new-multiselect .multiselect__input::placeholder {
  @apply text-grayDark !important;
}

.new-multiselect .multiselect__input {
  line-height: 150%;
  background: inherit !important;
  @apply text-blue-800 m-0 p-0 !important;
}

.new-multiselect .multiselect__tags {
  background: inherit !important;
  @apply bg-transparent rounded-full pl-8 py-3 !important;
}

.new-multiselect .multiselect__content-wrapper {
  border-top: 1px solid !important;
  @apply mt-1.5 border rounded-xl border-guestio-gray-300 z-50 !important;
}

.new-multiselect .multiselect__single {
  background: inherit !important;
  @apply m-0 p-0 truncate !important;
}

.new-multiselect .multiselect__option {
  @apply px-8 py-4 flex !important;
}

.new-multiselect .multiselect__option--highlight {
  @apply text-white !important;
}

.option__image {
  height: 38px;
  @apply rounded-sm;
}

.option__desc {
  @apply flex flex-col ml-3;
}

.option__title {
  @apply font-semibold text-sm;
}

.new-multiselect .multiselect__spinner {
  background: inherit !important;
  @apply z-30;
}

.new-multiselect .multiselect__spinner::before {
  border-top-color: #6304d1 !important;
}

.new-multiselect .multiselect__spinner::after {
  border-top-color: #6304d1 !important;
}
</style>
